import * as React from 'react'

function Chart(props) {
  return (
    <svg
      width={64}
      height={61}
      viewBox="0 0 64 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_61_740)">
        <path
          d="M5.556 38.222h6.222c.859 0 1.555.697 1.555 1.556v15.555c0 .86-.696 1.556-1.555 1.556H5.556A1.556 1.556 0 014 55.333V39.778c0-.86.697-1.556 1.556-1.556z"
          fill="url(#paint0_linear_61_740)"
        />
        <path
          d="M21.11 25.778h6.223c.86 0 1.556.696 1.556 1.555v28c0 .86-.697 1.556-1.556 1.556h-6.222a1.556 1.556 0 01-1.555-1.556v-28c0-.859.696-1.555 1.555-1.555z"
          fill="url(#paint1_linear_61_740)"
        />
        <path
          d="M36.667 32h6.222c.86 0 1.555.697 1.555 1.556v21.777c0 .86-.696 1.556-1.555 1.556h-6.222a1.556 1.556 0 01-1.556-1.556V33.556c0-.86.697-1.556 1.556-1.556z"
          fill="url(#paint2_linear_61_740)"
        />
        <path
          d="M52.222 22.667h6.222c.86 0 1.556.696 1.556 1.555v31.111c0 .86-.697 1.556-1.556 1.556h-6.222a1.556 1.556 0 01-1.555-1.556v-31.11c0-.86.696-1.556 1.555-1.556z"
          fill="url(#paint3_linear_61_740)"
        />
        <path
          d="M55.333 4a4.672 4.672 0 00-4.666 4.667 4.61 4.61 0 00.266 1.49l-7.787 4.629a4.626 4.626 0 00-7.45.997l-6.868-3.402c.034-.2.054-.401.061-.603a4.668 4.668 0 10-8.977 1.779l-8.91 6.649a4.614 4.614 0 00-2.335-.65 4.667 4.667 0 104.666 4.666 4.63 4.63 0 00-.312-1.64l8.998-6.716a4.568 4.568 0 005.403-.709l7.81 3.869A4.663 4.663 0 0044.445 18c0-.119-.026-.23-.035-.346l8.47-5.036a4.62 4.62 0 002.454.715 4.667 4.667 0 000-9.333z"
          fill="url(#paint4_linear_61_740)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_61_740"
          x={0}
          y={0}
          width={64}
          height={64}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.00392157 0 0 0 0 0.792157 0 0 0 0 0.945098 0 0 0 1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_61_740"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_61_740"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_61_740"
          x1={4}
          y1={43.891}
          x2={13.3333}
          y2={43.891}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_61_740"
          x1={19.5557}
          y1={35.2253}
          x2={28.8891}
          y2={35.2253}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_61_740"
          x1={35.1109}
          y1={39.5581}
          x2={44.4442}
          y2={39.5581}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_61_740"
          x1={50.6667}
          y1={33.0591}
          x2={60}
          y2={33.0591}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_61_740"
          x1={4}
          y1={11.5581}
          x2={60}
          y2={11.5581}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Chart
