import * as React from 'react'

function Darkmode(props) {
  return (
    <svg
      width={56}
      height={28}
      viewBox="0 0 56 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={56} height={28} rx={14} fill="#E6E6E6" />
      <circle cx={14} cy={14} r={11.2} fill="#161616" />
      <path
        d="M48.962 16.795a7.5 7.5 0 01-9.757-9.757A7.503 7.503 0 0042 21.5a7.502 7.502 0 006.962-4.705z"
        stroke="#161616"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Darkmode
