import * as React from 'react'

function Mail(props) {
  return (
    <svg
      width={81}
      height={80}
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M73.833 40V15H7.167v50H40.5"
        stroke="url(#paint0_linear_318_713)"
        strokeWidth={7}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.167 60l8.333 6.667L73.833 50"
        stroke="url(#paint1_linear_318_713)"
        strokeWidth={7}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.167 15L40.5 40l33.333-25"
        stroke="url(#paint2_linear_318_713)"
        strokeWidth={7}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_318_713"
          x1={7.16669}
          y1={30.1836}
          x2={73.8334}
          y2={30.1836}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_318_713"
          x1={52.1667}
          y1={55.0612}
          x2={73.8334}
          y2={55.0612}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_318_713"
          x1={7.16669}
          y1={22.5918}
          x2={73.8334}
          y2={22.5918}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Mail
