import * as React from 'react'

function ScaleInfra(props) {
  return (
    <svg
      width={56}
      height={56}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_68_910)" clipPath="url(#clip0_68_910)">
        <path
          d="M43.662 14.203C42.89 6.243 36.16 0 28 0S13.112 6.243 12.338 14.203c-5.225.743-9.254 5.246-9.254 10.673 0 5.944 4.836 10.78 10.78 10.78h12.495V23.648l-2.17 2.171a1.64 1.64 0 01-2.32-2.32l4.971-4.971a1.64 1.64 0 012.32 0l4.972 4.971a1.64 1.64 0 01-2.32 2.32l-2.171-2.17v12.007h12.495c5.944 0 10.78-4.836 10.78-10.78 0-5.427-4.03-9.93-9.254-10.673z"
          fill="url(#paint0_linear_68_910)"
        />
        <path
          d="M32.295 38.937h-8.59A4.3 4.3 0 0028 43.197a4.3 4.3 0 004.295-4.26z"
          fill="url(#paint1_linear_68_910)"
        />
        <path
          d="M28 46.314c-4.11 0-7.465-3.291-7.572-7.377H8.685c.07.638.168 1.272.295 1.898a1.64 1.64 0 001.608 1.313h2.967c.086.22.177.44.272.657l-2.098 2.098a1.64 1.64 0 00-.208 2.066 21.535 21.535 0 006.06 6.06 1.64 1.64 0 002.065-.209l2.099-2.098c.216.096.436.187.657.273v2.967c0 .78.549 1.451 1.313 1.607a21.514 21.514 0 008.57 0 1.64 1.64 0 001.314-1.607v-2.967c.22-.086.44-.177.657-.273l2.098 2.098a1.64 1.64 0 002.065.209 21.502 21.502 0 006.06-6.06 1.64 1.64 0 00-.208-2.066l-2.098-2.098c.096-.217.187-.436.273-.657h2.966a1.64 1.64 0 001.608-1.313c.127-.626.224-1.26.295-1.898H35.573c-.107 4.086-3.462 7.377-7.573 7.377z"
          fill="url(#paint2_linear_68_910)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_68_910"
          x={-0.916382}
          y={-4}
          width={57.8329}
          height={64.0001}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.00392157 0 0 0 0 0.792157 0 0 0 0 0.945098 0 0 0 1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_68_910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_68_910"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_68_910"
          x1={3.08362}
          y1={10.8278}
          x2={52.9165}
          y2={10.8278}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_68_910"
          x1={23.7051}
          y1={40.2309}
          x2={32.2955}
          y2={40.2309}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_68_910"
          x1={8.68527}
          y1={44.1189}
          x2={47.3148}
          y2={44.1189}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <clipPath id="clip0_68_910">
          <path fill="#fff" d="M0 0H56V56H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ScaleInfra
