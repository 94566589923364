import fomoImg from 'assets/images/fomo.png'
import icpImg from 'assets/images/icp.png'
import octImg from 'assets/images/oct.png'
import styles from './Partners.module.css'

function Partners() {
  return (
    <div className={styles.showcase}>
      <h2 className="gradient-text mb-40 center big-font">PARTNERS</h2>
      <div className={styles.gradientBorder}>
        <div className={styles.partners}>
          <div className="d-flex align-items-center">
            <img src={fomoImg} alt="FOMO Group" />
          </div>
          <div className="d-flex align-items-center">
            <img src={icpImg} alt="DINFINITY" />
          </div>
          <div className="d-flex align-items-center">
            <img src={octImg} alt="OCTOPUS Network" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partners
