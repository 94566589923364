import * as React from 'react'

function Facebook(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      width="28px"
      height="28px"
      {...props}
    >
      <path
        d="M22.96 2.238H5.04A2.806 2.806 0 002.237 5.04v17.922c0 1.543 1.258 2.8 2.801 2.8h17.922c1.543 0 2.8-1.257 2.8-2.8V5.039c0-1.543-1.257-2.8-2.8-2.8zm-2.241 8.403h-1.117c-1.2 0-1.68.28-1.68 1.12v1.68h2.797l-.559 2.797h-2.238v8.403h-2.8v-8.403h-2.243v-2.797h2.242v-1.68c0-2.241 1.117-3.921 3.36-3.921 1.624 0 2.238.558 2.238.558zm0 0"
        fill="#929394"
      />
    </svg>
  )
}

export default Facebook
