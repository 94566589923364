import Discord from 'assets/icons/dark/Discord'
import Telegram from 'assets/icons/dark/Telegram'
import Twitter from 'assets/icons/dark/Twitter'
import Youtube from 'assets/icons/dark/Youtube'
import Facebook from 'assets/icons/Facebook'
import Mail from 'assets/icons/Mail'
import Medium from 'assets/icons/Medium'
import clsx from 'clsx'
import Popup from 'components/popup/Popup'
import React, { useState } from 'react'
import styles from './Footer.module.css'

function Footer() {
  const [isShowPopup, setShowPopup] = useState(false)
  const handleSubmit = (e) => {
    e.preventDefault()
    setShowPopup(true)
  }

  return (
    <div className={styles.footer}>
      {isShowPopup && (
        <Popup
          icon={<Mail />}
          title="Successfully"
          subTitle="Your submission has been sent"
          callback={() => setShowPopup(false)}
        />
      )}
      <div className={clsx(styles.ctn, 'd-flex', 'space-between')}>
        <div className={styles.left}>
          <h3>Sign up for our newsletter</h3>

          <form
            className={clsx(styles.form, 'bg-color')}
            onSubmit={handleSubmit}
          >
            <input
              className="bg-color"
              type="text"
              name=""
              id=""
              placeholder="Your mail"
            />
            <button type="submit">Submit</button>
          </form>

          <div
            className="align-items-center space-between"
            style={{ width: 200, marginBottom: 8, display: 'flex' }}
          >
            <a
              href="https://twitter.com/octanlabs"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter />
            </a>
            <a href="#">
              <Discord />
            </a>
            <a
              href="https://t.me/octannetwork"
              target="_blank"
              rel="noreferrer"
            >
              <Telegram />
            </a>
            <a href="#">
              <Youtube />
            </a>
            <a
              href="https://fb.com/OctanNetwork"
              target="_blank"
              rel="noreferrer"
            >
              <Facebook />
            </a>
            <a
              href="https://octannetwork.medium.com"
              target="_blank"
              rel="noreferrer"
            >
              <Medium />
            </a>
          </div>
          <span>info@octan.network</span>
        </div>
        <div className={clsx(styles.right, 'd-flex')}>
          <div className={styles.grid}>
            <div className={styles.title}>Quick Link</div>
            <ul className={styles.list}>
              <li className={styles.item}>
                <a href="#">Home</a>
              </li>
              <li className={styles.item}>
                <a href="#solutions">Solutions</a>
              </li>
              <li className={styles.item}>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSd9FJnpaU-fo-Hnq3crFW32FAGvo8QsPNCLiqCfIk6XeU1ecA/viewform"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Us
                </a>
              </li>
              <li className={styles.item}>
                <a
                  href="https://drive.google.com/drive/folders/126hCmdkyKFAWVUhlRqRLgkJuBJ9TJ18r"
                  target="_blank"
                  rel="noreferrer"
                >
                  Documents
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.grid}>
            <div className={styles.title}>Products</div>
            <ul className={styles.list}>
              <li className={styles.item}>Reputation Ranking System</li>
              <li className={styles.item}>Soulbound</li>
              <li className={styles.item}>Appchain POR</li>
              <li className={styles.item}>Web3 infra</li>
            </ul>
          </div>
          <div className={styles.grid}>
            <div className={styles.title}>Tools/SDKs</div>
            <ul className={styles.list}>
              <li className={styles.item}>NFT Marketplace</li>
              <li className={styles.item}>Login method</li>
              <li className={styles.item}>Defi facilities</li>
              <li className={styles.item}>RPC/API</li>
            </ul>
          </div>
          <div className={styles.grid}>
            <div className={styles.title}>Company</div>
            <ul className={styles.list}>
              <li className={styles.item}>Enterprise</li>
              <li className={styles.item}>Blog</li>
              <li className={styles.item}>Pricing</li>
              <li className={styles.item}>Careers</li>
              <li className={styles.item}>Terms</li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.copy}>Copyright © 2022 Octan Network</div>
    </div>
  )
}

export default Footer
