import Content from 'components/content/Content'
import Footer from 'components/footer/Footer'
import Introduction from 'components/introduction/Introduction'
import Navbar from 'components/navbar/Navbar'
import Partners from 'components/partners/Partners'

function App() {
  return (
    <div className="App">
      <Navbar />
      <Introduction />
      <Content />
      <Partners />
      <Footer />
    </div>
  )
}

export default App
