import * as React from 'react'

function Twitter(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.295 6.881a9.433 9.433 0 01-2.705.741 4.723 4.723 0 002.071-2.607 9.405 9.405 0 01-2.991 1.143 4.712 4.712 0 00-8.028 4.298 13.377 13.377 0 01-9.71-4.923 4.713 4.713 0 001.458 6.29 4.7 4.7 0 01-2.133-.59v.061a4.712 4.712 0 003.779 4.62 4.746 4.746 0 01-2.128.08 4.714 4.714 0 004.401 3.271 9.452 9.452 0 01-5.851 2.017c-.376 0-.751-.021-1.125-.065a13.323 13.323 0 007.22 2.116c8.668 0 13.405-7.178 13.405-13.403 0-.202-.005-.407-.014-.609a9.575 9.575 0 002.35-2.437l.001-.003z"
        fill="#929394"
      />
    </svg>
  )
}

export default Twitter
