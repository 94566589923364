import * as React from 'react'

function Telegram(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.433 4.771L3.94 12.511c-.633.212-.563 1.127.07 1.338l4.926 1.548 1.97 6.192c.141.492.775.633 1.126.281l2.885-2.674 5.489 4.011a.74.74 0 001.126-.422l3.8-16.325c.21-1.196-.845-2.11-1.9-1.689zm-11.4 11.822l-.633 3.659-1.407-5.066 13.721-9.007-11.68 10.414z"
        fill="#929394"
      />
    </svg>
  )
}

export default Telegram
