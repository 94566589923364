import Button from 'components/button/Button'
import styles from './Popup.module.css'

function Popup({ icon, title, subTitle, callback }) {
  return (
    <div className={styles.model}>
      <div className={styles.popup}>
        <div className={styles.popupIcon}>{icon}</div>
        <div className={styles.popupTitle}>{title}</div>
        <div className={styles.popupSubTitle}>{subTitle}</div>
        <Button style={{ width: '100%' }} onClick={callback}>
          Close
        </Button>
      </div>
    </div>
  )
}

export default Popup
