import * as React from 'react'

function Discord(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.235 6.215A20.735 20.735 0 0017.17 4.67a.078.078 0 00-.082.037c-.218.383-.46.882-.63 1.276a19.253 19.253 0 00-5.69 0 12.81 12.81 0 00-.64-1.276.08.08 0 00-.082-.04c-1.778.301-3.478.83-5.066 1.547a.073.073 0 00-.033.028C1.72 10.986.835 15.612 1.27 20.182a.083.083 0 00.032.057 20.77 20.77 0 006.215 3.09.08.08 0 00.087-.027c.48-.642.905-1.323 1.271-2.035a.077.077 0 00-.043-.108 13.678 13.678 0 01-1.94-.91.078.078 0 01-.033-.1.077.077 0 01.024-.03c.13-.097.261-.197.386-.298a.078.078 0 01.08-.01c4.073 1.829 8.483 1.829 12.508 0a.078.078 0 01.081.009c.125.102.255.202.386.299a.078.078 0 01-.006.13 12.8 12.8 0 01-1.942.909.077.077 0 00-.051.077c0 .01.003.022.008.032.373.712.8 1.39 1.27 2.034a.08.08 0 00.088.03 20.703 20.703 0 006.223-3.092.08.08 0 00.034-.056c.518-5.283-.87-9.872-3.68-13.94a.062.062 0 00-.033-.028zM9.484 17.4c-1.226 0-2.237-1.109-2.237-2.468 0-1.36.991-2.468 2.237-2.468 1.254 0 2.256 1.117 2.236 2.468 0 1.36-.99 2.468-2.236 2.468zm8.27 0c-1.227 0-2.237-1.109-2.237-2.468 0-1.36.99-2.468 2.236-2.468 1.255 0 2.257 1.117 2.237 2.468 0 1.36-.98 2.468-2.236 2.468z"
        fill="#929394"
      />
    </svg>
  )
}

export default Discord
