import ArrowRight from 'assets/icons/ArrowRight'
import clsx from 'clsx'
import * as React from 'react'
import styles from './Card.module.css'

function Card({
  icon,
  title,
  content = [],
  className,
  extStyle,
  outline,
  children,
}) {
  const OutlineCard = (
    <div
      className={clsx(
        'align-items-center',
        'text-center',
        'd-flex-column',
        styles.outline,
        className
      )}
      style={extStyle}
    >
      {icon}
      <div className={styles.title}>{title}</div>
      <ul className={styles.content}>
        {content.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      {children}
    </div>
  )
  const PrimaryCard = (
    <div
      className={clsx('d-flex-column', styles.primary, className)}
      style={extStyle}
    >
      {icon}
      <div className={styles.title}>{title}</div>
      <ul className={styles.content} style={{ paddingBottom: 80 }}>
        {content.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <div className={styles.link}>
        <span>Learn more</span>
        <ArrowRight />
      </div>
    </div>
  )

  const SecondaryCard = (
    <div
      className={clsx('d-flex-column', styles.secondary, className)}
      style={extStyle}
    >
      {icon}
      <div className={styles.secondaryTitle}>{title}</div>
      <ul className={styles.secondaryContent}>
        {content.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  )

  return outline ? OutlineCard : icon ? PrimaryCard : SecondaryCard
}

export default Card
