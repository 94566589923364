import Logo from 'assets/icons/Logo'
import prizeImg from 'assets/images/prize.png'
import Button from 'components/button/Button'
import * as React from 'react'
import styles from './Introduction.module.css'

function Introduction() {
  const documentLink =
    'https://drive.google.com/drive/folders/126hCmdkyKFAWVUhlRqRLgkJuBJ9TJ18r'
  const contactLink =
    'https://docs.google.com/forms/d/e/1FAIpQLSd9FJnpaU-fo-Hnq3crFW32FAGvo8QsPNCLiqCfIk6XeU1ecA/viewform'

  return (
    <div className={styles.ctn}>
      <Logo />
      <h1 className={styles.title}>FUELING WEB3 APPLICATIONS</h1>
      <p className={styles.subTitle}>
        We provide Reputation Ranking, inclusive appchain solutions & Web3
        services optimized for GameFi & SocialFi apps on multiple layer-1
        ecosystems
      </p>
      <div className={styles.groupBtn}>
        <a href={documentLink} target="_blank" rel="noreferrer">
          <Button
            outline
            style={{ width: 240, marginInline: 20, marginBlock: 8 }}
          >
            Documentation
          </Button>
        </a>
        <a href={contactLink} target="_blank" rel="noreferrer">
          <Button style={{ width: 240, marginInline: 20, marginBlock: 8 }}>
            Contact us
          </Button>
        </a>
      </div>
      <img src={prizeImg} alt="" style={{ maxWidth: '100%' }} />
    </div>
  )
}

export default Introduction
