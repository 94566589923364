import * as React from 'react'

function ChevronDown(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.414 5.86H3.586c-.385 0-.6.406-.361.683l6.414 7.437a.489.489 0 00.722 0l6.414-7.437c.239-.277.024-.684-.36-.684z"
        fill="#fff"
      />
    </svg>
  )
}

export default ChevronDown
