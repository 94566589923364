import * as React from 'react'

function SDK(props) {
  return (
    <svg
      width={56}
      height={56}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_68_900)" clipPath="url(#clip0_68_900)">
        <path
          d="M39.813 50.75a1.33 1.33 0 01-1.313 1.313h-21a1.313 1.313 0 010-2.626h2.433l.7-3.5h14.735l.7 3.5H38.5a1.33 1.33 0 011.313 1.313zm12.25-38.5V38.5a4.813 4.813 0 01-4.813 4.813H8.75A4.813 4.813 0 013.937 38.5V12.25a1.313 1.313 0 012.626 0v21.438h42.874V12.25a1.313 1.313 0 012.626 0zM42 3.937H14A4.813 4.813 0 009.187 8.75v22.313h37.626V8.75A4.813 4.813 0 0042 3.937zM26.687 27.633l-7.227-4.165a1.347 1.347 0 01-.648-1.137V14l7.875 4.55v9.082zm-6.562-15.925l7.21-4.165a1.382 1.382 0 011.33 0l7.21 4.165L28 16.258l-7.875-4.55zM37.188 22.33a1.348 1.348 0 01-.648 1.138l-7.227 4.165v-9.1L37.188 14v8.33z"
          fill="url(#paint0_linear_68_900)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_68_900"
          x={-0.0625}
          y={-0.0625}
          width={56.125}
          height={56.125}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.00392157 0 0 0 0 0.792157 0 0 0 0 0.945098 0 0 0 1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_68_900"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_68_900"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_68_900"
          x1={3.9375}
          y1={18.5517}
          x2={52.0625}
          y2={18.5517}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <clipPath id="clip0_68_900">
          <path fill="#fff" d="M0 0H56V56H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SDK
