import * as React from 'react'

function Youtube(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.4 7.596a3.249 3.249 0 00-.893-1.481 3.556 3.556 0 00-1.56-.865c-2.19-.577-10.962-.577-10.962-.577a89.34 89.34 0 00-10.948.548 3.684 3.684 0 00-1.56.886A3.377 3.377 0 00.57 7.594 33.917 33.917 0 000 14c-.013 2.147.177 4.29.57 6.406.16.558.47 1.068.903 1.48.433.41.972.708 1.564.866 2.219.576 10.948.576 10.948.576a89.527 89.527 0 0010.962-.548 3.556 3.556 0 001.56-.866c.426-.403.734-.915.892-1.48.403-2.112.598-4.258.584-6.407a30.997 30.997 0 00-.583-6.433v.002zM11.196 17.992V10.01l7.299 3.992-7.299 3.991z"
        fill="#929394"
      />
    </svg>
  )
}

export default Youtube
