import * as React from 'react'

function Solution(props) {
  return (
    <svg
      width={81}
      height={80}
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.5}
        width={80}
        height={80}
        rx={40}
        fill="url(#paint0_linear_46_1967)"
        fillOpacity={0.2}
      />
      <g clipPath="url(#clip0_46_1967)">
        <path
          d="M46.305 54.074l-4.931-2.846a.75.75 0 00-.75 0l-4.931 2.846a.752.752 0 00-.375.65V60.4a.75.75 0 00.375.65l4.931 2.85a.746.746 0 00.75 0l4.931-2.846a.75.75 0 00.375-.65v-5.68a.75.75 0 00-.375-.65zM41 52.743l3.431 1.981L41 56.705l-3.431-1.981L41 52.743zm-4.181 3.28L40.249 58v3.947l-3.431-1.977.001-3.947zm8.361 3.947l-3.431 1.981V58l3.431-1.981v3.951z"
          fill="url(#paint1_linear_46_1967)"
        />
        <path
          d="M35.693 25.926l4.931 2.846a.747.747 0 00.75 0l4.931-2.846a.751.751 0 00.375-.65V19.6a.751.751 0 00-.375-.65l-4.931-2.85a.75.75 0 00-.75 0l-4.931 2.847a.75.75 0 00-.375.65v5.679a.751.751 0 00.375.65zm1.125-5.026l3.431 1.981v3.947l-3.431-1.985V20.9zm8.362 3.943l-3.431 1.981v-3.947L45.18 20.9v3.943zM41 17.616l3.43 1.984L41 21.578 37.568 19.6 41 17.616z"
          fill="url(#paint2_linear_46_1967)"
        />
        <path
          d="M50.9 34.7l4.931 2.847a.747.747 0 00.75 0l4.933-2.847a.747.747 0 00.375-.649v-5.684a.751.751 0 00-.375-.65l-4.931-2.846a.75.75 0 00-.75 0L50.9 27.717a.75.75 0 00-.375.65v5.679a.748.748 0 00.375.654zm1.125-5.029l3.431 1.981v3.947l-3.431-1.981v-3.947zm8.362 3.947l-3.431 1.981v-3.952l3.431-1.981v3.952zm-4.181-7.227l3.431 1.981-3.431 1.981-3.431-1.981 3.431-1.981z"
          fill="url(#paint3_linear_46_1967)"
        />
        <path
          d="M31.1 27.715l-4.936-2.846a.75.75 0 00-.75 0l-4.931 2.846a.752.752 0 00-.375.65v5.679a.749.749 0 00.375.649l4.931 2.848a.752.752 0 00.75 0l4.936-2.848a.748.748 0 00.375-.649v-5.679a.751.751 0 00-.375-.65zm-5.311-1.331l3.431 1.981-3.431 1.981-3.431-1.981 3.431-1.981zm-4.181 3.28l3.431 1.981v3.947l-3.431-1.981v-3.947zm8.362 3.947l-3.431 1.981v-3.947l3.431-1.981v3.947z"
          fill="url(#paint4_linear_46_1967)"
        />
        <path
          d="M61.536 45.273L56.6 42.426a.75.75 0 00-.75 0l-4.931 2.847a.748.748 0 00-.375.649V51.6a.748.748 0 00.375.649l4.935 2.851a.746.746 0 00.75 0l4.932-2.847a.748.748 0 00.375-.649v-5.68a.75.75 0 00-.375-.651zm-5.307-1.331l3.432 1.98-3.432 1.978-3.429-1.978 3.429-1.98zm-4.181 3.28l3.431 1.978v3.946l-3.431-1.981v-3.943zm8.363 3.946l-3.432 1.981V49.2l3.432-1.981v3.949z"
          fill="url(#paint5_linear_46_1967)"
        />
        <path
          d="M31.077 45.273l-4.931-2.846a.75.75 0 00-.75 0l-4.932 2.846a.751.751 0 00-.375.65V51.6a.749.749 0 00.375.649L25.4 55.1a.747.747 0 00.75 0l4.931-2.847a.747.747 0 00.375-.649v-5.681a.751.751 0 00-.379-.65zm-5.306-1.331l3.429 1.981-3.429 1.977-3.432-1.98 3.432-1.978zm-4.182 3.28l.527.3 2.905 1.678v3.95l-3.432-1.981v-3.947zm4.932 5.928V49.2l2.9-1.677.527-.3v3.947l-3.427 1.98z"
          fill="url(#paint6_linear_46_1967)"
        />
        <path
          d="M46.856 35.477v-.007c-.008-.013-.02-.02-.028-.032a7.399 7.399 0 00-11.68.032 7.378 7.378 0 000 9.1l.01.011a7.41 7.41 0 0011.64.075 7.4 7.4 0 00.06-9.187l-.002.008zm-.731 8.042a9.028 9.028 0 00-1.105-.407c.212-.814.336-1.648.372-2.488h1.782a6.115 6.115 0 01-1.049 2.895zm-11.32-2.895h1.8c.035.84.16 1.674.372 2.487a9.012 9.012 0 00-1.121.416 6.19 6.19 0 01-1.051-2.903zm1.053-4.1c.364.163.739.302 1.121.416a11.79 11.79 0 00-.372 2.487h-1.8a6.185 6.185 0 011.051-2.906v.003zm4.541-.243a13.44 13.44 0 01-1.86-.2 3.81 3.81 0 011.86-2.161v2.361zm0 1.2v1.946h-2.593c.037-.743.151-1.48.342-2.2.743.142 1.496.225 2.252.251l-.001.003zm0 3.146v1.943c-.756.027-1.508.112-2.251.254a10.64 10.64 0 01-.342-2.2l2.593.003zm0 3.146v2.361a3.81 3.81 0 01-1.86-2.161c.615-.11 1.237-.178 1.861-.203l-.001.003zm1.2 0c.624.024 1.245.09 1.86.2a3.8 3.8 0 01-1.86 2.161v-2.361zm0-1.2v-1.949h2.594c-.038.743-.152 1.48-.343 2.2a14.801 14.801 0 00-2.25-.254l-.001.003zm0-3.146v-1.949a14.688 14.688 0 002.251-.254c.19.72.305 1.457.343 2.2l-2.594.003zm0-3.146v-2.364a3.806 3.806 0 011.861 2.162c-.615.109-1.236.175-1.86.199l-.001.003zm2.428-1.687c.474.265.91.591 1.3.97a7.905 7.905 0 01-.683.238 7.474 7.474 0 00-.617-1.211v.003zM37.353 35.8a8.39 8.39 0 01-.683-.238c.39-.378.827-.705 1.3-.971a7.474 7.474 0 00-.617 1.209zm0 8.45c.17.42.376.826.619 1.209a6.268 6.268 0 01-1.3-.971 7.43 7.43 0 01.681-.239v.001zm7.292 0c.241.072.47.152.687.238-.39.377-.827.701-1.3.965a7.58 7.58 0 00.613-1.203zm.747-4.826c-.036-.84-.16-1.674-.372-2.487a9.004 9.004 0 001.12-.416 6.19 6.19 0 011.053 2.9l-1.801.003z"
          fill="url(#paint7_linear_46_1967)"
        />
        <path
          d="M40.25 30.335v.651a.75.75 0 001.5 0v-.651a.75.75 0 10-1.5 0z"
          fill="url(#paint8_linear_46_1967)"
        />
        <path
          d="M41.75 49.855V49.2a.75.75 0 10-1.5 0v.651a.75.75 0 001.5 0v.004z"
          fill="url(#paint9_linear_46_1967)"
        />
        <path
          d="M32.172 35.864l.564.326a.75.75 0 10.75-1.3l-.564-.326a.75.75 0 00-.75 1.3z"
          fill="url(#paint10_linear_46_1967)"
        />
        <path
          d="M49.827 44.326L49.263 44a.75.75 0 10-.75 1.3l.564.326a.751.751 0 00.75-1.3z"
          fill="url(#paint11_linear_46_1967)"
        />
        <path
          d="M32.736 44l-.564.326a.75.75 0 10.75 1.3l.564-.326a.753.753 0 00.275-1.025.75.75 0 00-1.025-.275z"
          fill="url(#paint12_linear_46_1967)"
        />
        <path
          d="M49.077 34.565l-.564.326a.75.75 0 00.75 1.3l.564-.326a.749.749 0 00.082-1.245.752.752 0 00-.832-.055z"
          fill="url(#paint13_linear_46_1967)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_46_1967"
          x1={0.5}
          y1={24.2938}
          x2={80.5}
          y2={24.2938}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_46_1967"
          x1={35.318}
          y1={55.0369}
          x2={46.68}
          y2={55.0369}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_46_1967"
          x1={35.318}
          y1={19.9088}
          x2={46.68}
          y2={19.9088}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_46_1967"
          x1={50.525}
          y1={28.681}
          x2={61.889}
          y2={28.681}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_46_1967"
          x1={20.108}
          y1={28.6776}
          x2={31.475}
          y2={28.6776}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_46_1967"
          x1={50.544}
          y1={46.2355}
          x2={61.911}
          y2={46.2355}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_46_1967"
          x1={20.089}
          y1={46.2361}
          x2={31.456}
          y2={46.2361}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_46_1967"
          x1={33.5779}
          y1={37.1064}
          x2={48.4265}
          y2={37.1064}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_46_1967"
          x1={40.25}
          y1={30.2382}
          x2={41.75}
          y2={30.2382}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_46_1967"
          x1={40.25}
          y1={49.1032}
          x2={41.75}
          y2={49.1032}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_46_1967"
          x1={31.7966}
          y1={35.0184}
          x2={33.8614}
          y2={35.0184}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_46_1967"
          x1={48.1376}
          y1={44.4543}
          x2={50.2024}
          y2={44.4543}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_46_1967"
          x1={31.7966}
          y1={44.4543}
          x2={33.8614}
          y2={44.4543}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_46_1967"
          x1={48.1376}
          y1={35.0194}
          x2={50.2024}
          y2={35.0194}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <clipPath id="clip0_46_1967">
          <path fill="#fff" transform="translate(17 16)" d="M0 0H48V48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Solution
