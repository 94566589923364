import clsx from 'clsx'
import * as React from 'react'
import styles from './Button.module.css'

function Button({ outline = false, children, style, onClick }) {
  return outline ? (
    <div
      onClick={onClick}
      style={style}
      className={clsx(styles.outline, styles.ctn)}
    >
      <span>{children}</span>
    </div>
  ) : (
    <div
      onClick={onClick}
      style={style}
      className={clsx(styles.primary, styles.ctn)}
    >
      <span>{children}</span>
    </div>
  )
}

export default Button
