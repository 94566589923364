import Bars from 'assets/icons/dark/Bars'
import ChevronDown from 'assets/icons/dark/ChevronDown'
import Close from 'assets/icons/dark/Close'
import octanLogo from 'assets/images/octan.png'
import clsx from 'clsx'
import * as React from 'react'
import { useState } from 'react'
import Darkmode from '../Darkmode'
import style from './Navbar.module.css'

const MENU = [
  { text: 'Solutions', to: '#solutions', hasChildren: false, children: [] },
  { text: 'Showcase', to: '#showcase', hasChildren: false, children: [] },
  { text: 'Customers', to: '#', hasChildren: false, children: [] },
  {
    text: 'About',
    to: '#',
    hasChildren: true,
    children: [
      { text: 'Blog', to: '#' },
      { text: 'Career', to: '#' },
    ],
  },
]

function Navbar() {
  const [isExpand, setExpand] = useState(false)
  const expandMenu = () => {
    setExpand(!isExpand)
  }

  return (
    <div className={style.navbar}>
      <div className={style.image}>
        <img src={octanLogo} alt="OCTAN" style={{ height: '100%' }} />
      </div>
      <div className={clsx(style.ctn)}>
        <ul className={clsx(style.menu, { expand: isExpand })}>
          {MENU.map((item, index) => (
            <a key={index} href={item.to}>
              <li className={style.item}>
                <div>
                  <span>{item.text}</span>
                  {item.hasChildren && <ChevronDown className="icon" />}
                </div>
                {item.hasChildren && (
                  <ul className={style.subMenu}>
                    {item.children.map((child, i) => (
                      <li key={i} className={style.subItem}>
                        {child.text}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            </a>
          ))}
          <li className={clsx(style.item)}>
            <div>
              <span className={style.addition}>Dark mode</span>
              <Darkmode />
            </div>
          </li>
        </ul>
        <a href="#" className={style.soulbound}>
          Soulbound
        </a>
        <div onClick={expandMenu} className={style.btn}>
          {isExpand ? <Close /> : <Bars />}
        </div>
      </div>
    </div>
  )
}

export default Navbar
