import * as React from 'react'

function Features(props) {
  return (
    <svg
      width={56}
      height={56}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_68_890)" clipPath="url(#clip0_68_890)">
        <path
          d="M6.005 12.541l-.476 2.927a1.751 1.751 0 002.574 1.813l.647-.358V24.5c0 .966.784 1.75 1.75 1.75h7v8.027c2.143 3.57 6.041 5.973 10.5 5.973s8.357-2.402 10.5-5.973V26.25h7a1.75 1.75 0 001.75-1.75v-7.577l.648.358a1.75 1.75 0 00.882.219 1.75 1.75 0 001.637-2.37l-.422-2.589 2.008-2.058a1.749 1.749 0 00-.988-2.952l-2.705-.413-1.226-2.611a1.75 1.75 0 00-3.168 0l-1.226 2.61-2.705.414a1.749 1.749 0 00-.988 2.952l2.008 2.058-.476 2.927a1.751 1.751 0 002.574 1.813l.647-.358v5.827H38.5V21h-8.75v-8.75h-10.5A1.75 1.75 0 0017.5 14v8.75h-5.25v-5.827l.648.358a1.75 1.75 0 00.882.219 1.75 1.75 0 001.637-2.37l-.422-2.589 2.008-2.058a1.749 1.749 0 00-.988-2.952l-2.705-.413-1.226-2.611a1.75 1.75 0 00-3.168 0L7.69 7.117l-2.705.414a1.749 1.749 0 00-.988 2.952l2.008 2.058zM21.875 24.5h12.25a.874.874 0 110 1.75h-12.25a.875.875 0 110-1.75zm0 5.25h12.25a.874.874 0 110 1.75h-12.25a.875.875 0 110-1.75zm0 5.25h12.25a.874.874 0 110 1.75h-12.25a.875.875 0 110-1.75z"
          fill="url(#paint0_linear_68_890)"
        />
        <path
          d="M42 28c0 7.732-6.268 14-14 14s-14-6.268-14-14H3.5v5.25c0 .966.784 1.75 1.75 1.75h2.963c.246.7.528 1.383.843 2.045l-2.092 2.093a1.75 1.75 0 000 2.474l7.424 7.424a1.75 1.75 0 002.475 0l2.092-2.092c.662.314 1.345.596 2.045.843v2.963c0 .966.784 1.75 1.75 1.75h10.5A1.75 1.75 0 0035 50.75v-2.963c.7-.246 1.383-.529 2.046-.843l2.092 2.092a1.75 1.75 0 002.474 0l7.424-7.424a1.75 1.75 0 000-2.474l-2.092-2.093c.314-.662.596-1.344.843-2.045h2.963a1.75 1.75 0 001.75-1.75V28H42z"
          fill="url(#paint1_linear_68_890)"
        />
        <path d="M31.5 12.25v7h7l-7-7z" fill="url(#paint2_linear_68_890)" />
      </g>
      <defs>
        <filter
          id="filter0_d_68_890"
          x={-0.500122}
          y={-0.5}
          width={57.0002}
          height={57}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.00392157 0 0 0 0 0.792157 0 0 0 0 0.945098 0 0 0 1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_68_890"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_68_890"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_68_890"
          x1={3.49988}
          y1={14.66}
          x2={52.5001}
          y2={14.66}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_68_890"
          x1={3.5}
          y1={35.44}
          x2={52.5}
          y2={35.44}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_68_890"
          x1={31.5}
          y1={14.3757}
          x2={38.5}
          y2={14.3757}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <clipPath id="clip0_68_890">
          <path fill="#fff" d="M0 0H56V56H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Features
