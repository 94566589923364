import clsx from 'clsx'
import Button from 'components/button/Button'
import * as React from 'react'
import styles from './VideoCard.module.css'

function VideoCard() {
  return (
    <div className={styles.ctn}>
      <div className={styles.content}>
        <div className={clsx(styles.title, 'mb-24')}>Empireland</div>
        <p className={clsx(styles.subTitle, 'mb-24')}>
          The first game on Octan Network. It is a strategy game added with RPG,
          RTS and MOBA elements.
        </p>
        <a href="https://test.empireland.io/" target="_blank" rel="noreferrer">
          <Button outline>Explore</Button>
        </a>
      </div>
      <div className={styles.video}>
        <iframe
          title="Empireland"
          src="https://www.youtube.com/embed/MgJy9fcocoI"
          frameBorder="0"
          style={{ aspectRatio: 16 / 9, width: '100%' }}
        ></iframe>
      </div>
    </div>
  )
}

export default VideoCard
