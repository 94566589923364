import Chart from 'assets/icons/Chart'
import Features from 'assets/icons/Features'
import Fingerprint from 'assets/icons/Fingerprint'
import OctanNetwork from 'assets/icons/OctanNetwork'
import ScaleInfra from 'assets/icons/ScaleInfra'
import SDK from 'assets/icons/SDK'
import Solution from 'assets/icons/Solution'
import rrsImage from 'assets/images/rrs.png'
import stackImg from 'assets/images/stack.png'
import clsx from 'clsx'
import Card from 'components/card/Card'
import VideoCard from 'components/card/VideoCard'
import * as React from 'react'
import style from './Content.module.css'

function Content() {
  const TestnetLink = () => {
    return (
      <a
        href="https://octopus-appchain-local-testnet.octan.network/"
        target="_blank"
        rel="noreferrer"
        id="testnet-link"
      >
        Testnet
      </a>
    )
  }
  return (
    <div className={style.content}>
      {/* RRS */}
      <div id="solutions" className={style.rrs}>
        <div className="d-flex align-items-center">
          <img src={rrsImage} alt="" className="w-50" />
          <div className={clsx('w-50', style.details)}>
            <h2 className="gradient-text mb-8">Reputation Ranking System</h2>
            <h3 className="mb-24">
              the CORE engine of Octan products & ecosystem, 1st of its kinds
            </h3>
            <ul style={{ paddingLeft: 16, color: '#929394' }}>
              <li className="mb-24">
                Used in Proof of Reputation on Octan Network appchain to empower
                developers & users all the bests
              </li>
              <li className="mb-24">
                Input value in soulbound token (i.e. DID of a user)
              </li>
              <li className="mb-24">
                Comparative & quantitative insight over accounts within
                communities
              </li>
            </ul>
          </div>
        </div>
        <div className="d-flex m--20 ">
          <Card
            className="w-33 m-20"
            icon={<Fingerprint />}
            title="Soulbound"
            content={[
              'Users self-manage one ID one profile across multiple chains and apps',
            ]}
            outline
          />
          <Card
            className="w-33 m-20"
            icon={<OctanNetwork />}
            title="Octan network"
            content={[
              'An Appchain powered by Proof of Reputation, optimized for gamefi & socialfi applications',
            ]}
            outline
          >
            <TestnetLink />
          </Card>

          <Card
            className="w-33 m-20"
            icon={<Solution />}
            title="Web3 Solutions"
            content={[
              'Help developers only focus on building their games and social apps without spending effort on Web3 infra problems',
            ]}
            outline
          />
        </div>
      </div>

      {/* BUILD YOUR OWN APPCHAIN */}
      <div className={style.feature}>
        <h2 className="gradient-text mb-40 center big-font">
          BUILD YOUR OWN APPCHAIN
        </h2>
        <h3>One-stop Web3 solutions for your own dapps & appchains</h3>
        <div className="d-flex">
          <div className="w-70 m--20 p-54">
            <div className="d-flex">
              <Card
                className="w-50 m-20"
                icon={<Features />}
                title="Octan Appchain"
                content={[
                  '- Proof of Reputation',
                  '- Pay gas by tokens',
                  '- MEV-migitation',
                ]}
              />
              <Card
                className="w-50 m-20"
                icon={<Chart />}
                title="Data Analytics "
                content={[
                  '- Global vs local measurement to give multi-dimentional insights',
                  '- Reputation Ranking Score',
                ]}
              />
            </div>
            <div className="d-flex">
              <Card
                className="w-50 m-20"
                icon={<ScaleInfra />}
                title="Web3 Infra"
                content={[
                  '- Stable RPC node services',
                  '- Scalable API services',
                  '- Improved NFT indexing',
                ]}
              />
              <Card
                className="w-50 m-20"
                icon={<SDK />}
                title="Tools/SDKs"
                content={[
                  '- DID and Soulbound token',
                  '- NFT marketplace, DeFi facilities',
                  '- Appchain SDKs',
                ]}
              />
            </div>
          </div>
          <div className="w-30 d-flex align-items-center">
            <img src={stackImg} alt="" style={{ width: '100%' }} />
          </div>
        </div>
      </div>

      {/* SHOWCASE */}
      <div id="showcase" className={style.showcase}>
        <h2 className="gradient-text mb-40 center big-font">SHOWCASE</h2>
        <h3 style={{ marginBottom: 80 }}>
          Proving the concept of Reputation Ranking Score & Proof of Reputation
          appchain
        </h3>
        <VideoCard />
        <div className="d-flex m--20 ">
          <Card
            className="w-50 m-20"
            title="Decentralized Identity (DID) portal "
            content={[
              'Allows users to mint Soulbound token, self-manage their ID & social profile on Octan Network and multiple chains. DID is associated with user reputation scores.',
            ]}
          />
          <Card
            className="w-50 m-20"
            title="Streaming protocol "
            content={[
              'Built on a novel distributed storage system (DSS) optimized for video streams, powered by Octan Network. The protocol will be opened for other developers to build UI/UX and plugins on the top.',
            ]}
          />
        </div>
      </div>
    </div>
  )
}

export default Content
