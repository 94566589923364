import * as React from 'react'

function Medium(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      width="28px"
      height="28px"
      {...props}
    >
      <path
        d="M25.2 2.238H2.8a.565.565 0 00-.562.563v22.398c0 .309.254.563.563.563h22.398a.565.565 0 00.563-.563V2.801a.565.565 0 00-.563-.563zm-2.802 5.32l-1.02 1.231a.353.353 0 00-.097.242v9.309c0 .09.035.176.098.238l1.02 1.328v.254H16.8v-.254l1.578-1.328a.325.325 0 00.101-.238v-8.328L13.914 20.16h-.863l-4.653-9.87v7.339a.54.54 0 00.122.34l1.558 1.937v.254H5.602v-.254L7.16 17.98a.54.54 0 00.121-.34V9.177A.315.315 0 007.2 8.96L6.16 7.559V7.28h3.973l4.27 9.063L18.48 7.28h3.918zm0 0"
        fill="#929394"
      />
    </svg>
  )
}

export default Medium
