import * as React from 'react'

function Fingerprint(props) {
  return (
    <svg
      width={81}
      height={80}
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.5}
        width={80}
        height={80}
        rx={40}
        fill="url(#paint0_linear_46_1865)"
        fillOpacity={0.2}
      />
      <path
        d="M43.388 54.648a.75.75 0 01-.732-.587.75.75 0 01.094-.561 19.155 19.155 0 003-10.328V40.75a.75.75 0 111.5 0v2.415a20.633 20.633 0 01-3.233 11.138.751.751 0 01-.63.345z"
        fill="url(#paint1_linear_46_1865)"
      />
      <path
        d="M34.5 41.5a.75.75 0 01-.75-.75V38.5a6.75 6.75 0 0113.447-.847.757.757 0 01-1.5.194 5.25 5.25 0 00-10.447.653v2.25a.75.75 0 01-.75.75z"
        fill="url(#paint2_linear_46_1865)"
      />
      <path
        d="M31.192 50.575a.75.75 0 01-.532-1.275l.938-.945a7.327 7.327 0 002.152-4.657.75.75 0 011.05-.636.75.75 0 01.45.74 8.827 8.827 0 01-2.565 5.61l-.96.938a.75.75 0 01-.533.225z"
        fill="url(#paint3_linear_46_1865)"
      />
      <path
        d="M47.858 52.487a.75.75 0 01-.69-1.042c.067-.157.127-.322.18-.487a.75.75 0 011.206-.327.752.752 0 01.218.806c-.067.196-.142.39-.224.593a.75.75 0 01-.69.457z"
        fill="url(#paint4_linear_46_1865)"
      />
      <path
        d="M48.75 49.818h-.195a.75.75 0 01-.555-.945c.502-1.861.754-3.78.75-5.708V38.5a8.146 8.146 0 00-2.483-5.888.754.754 0 011.05-1.08A9.689 9.689 0 0150.25 38.5v4.665a23.475 23.475 0 01-.75 6.098.75.75 0 01-.75.555z"
        fill="url(#paint5_linear_46_1865)"
      />
      <path
        d="M29.573 47.95a.748.748 0 01-.697-1.038.748.748 0 01.164-.245l.435-.434a4.306 4.306 0 001.275-3.068V38.5a9.75 9.75 0 0113.958-8.797.75.75 0 11-.645 1.35A8.25 8.25 0 0032.25 38.5v4.665a5.82 5.82 0 01-1.71 4.125l-.435.443a.75.75 0 01-.532.217z"
        fill="url(#paint6_linear_46_1865)"
      />
      <path
        d="M43.388 54.648a.75.75 0 01-.732-.587.75.75 0 01.094-.561 19.155 19.155 0 003-10.328V40.75a.75.75 0 111.5 0v2.415a20.633 20.633 0 01-3.233 11.138.751.751 0 01-.63.345z"
        fill="url(#paint7_linear_46_1865)"
      />
      <path
        d="M34.5 41.5a.75.75 0 01-.75-.75V38.5a6.75 6.75 0 0113.447-.847.757.757 0 01-1.5.194 5.25 5.25 0 00-10.447.653v2.25a.75.75 0 01-.75.75z"
        fill="url(#paint8_linear_46_1865)"
      />
      <path
        d="M31.192 50.575a.75.75 0 01-.532-1.275l.938-.945a7.327 7.327 0 002.152-4.657.75.75 0 011.05-.636.75.75 0 01.45.74 8.827 8.827 0 01-2.565 5.61l-.96.938a.75.75 0 01-.533.225z"
        fill="url(#paint9_linear_46_1865)"
      />
      <path
        d="M39.45 54.955a.75.75 0 01-.57-1.237 16.185 16.185 0 003.517-7.163.75.75 0 01.886-.555.752.752 0 01.584.892 17.736 17.736 0 01-3.847 7.83.75.75 0 01-.57.233z"
        fill="url(#paint10_linear_46_1865)"
      />
      <path
        d="M34.252 51.752a.748.748 0 01-.532-.217.75.75 0 01.03-1.035 10.238 10.238 0 003-7.305V38.5a3.75 3.75 0 017.5 0V43.81a.752.752 0 11-1.5-.12V38.5a2.25 2.25 0 00-4.5 0v4.665a11.77 11.77 0 01-3.465 8.37.75.75 0 01-.532.218z"
        fill="url(#paint11_linear_46_1865)"
      />
      <path
        d="M36.375 53.875a.752.752 0 01-.697-1.038.75.75 0 01.165-.244c.224-.225.442-.45.644-.69a.75.75 0 011.14.975c-.232.27-.465.525-.75.75a.75.75 0 01-.502.247z"
        fill="url(#paint12_linear_46_1865)"
      />
      <path
        d="M38.362 51.37a.75.75 0 01-.637-1.148 13.245 13.245 0 002.025-7.057V38.5a.75.75 0 111.5 0v4.665A14.774 14.774 0 0139 51.017a.748.748 0 01-.638.353z"
        fill="url(#paint13_linear_46_1865)"
      />
      <path
        d="M52.5 43a.75.75 0 01-.75-.75V38.5a11.25 11.25 0 00-20.25-6.75.75.75 0 11-1.2-.9 12.75 12.75 0 0122.95 7.65v3.75a.75.75 0 01-.75.75z"
        fill="url(#paint14_linear_46_1865)"
      />
      <path
        d="M28.5 43a.75.75 0 01-.75-.75V38.5a12.645 12.645 0 01.983-4.905.75.75 0 011.38.57 11.347 11.347 0 00-.863 4.335v3.75a.75.75 0 01-.75.75z"
        fill="url(#paint15_linear_46_1865)"
      />
      <path
        d="M21.75 30.25a.75.75 0 01-.75-.75v-8.25a.75.75 0 01.75-.75H30a.75.75 0 110 1.5h-7.5v7.5a.75.75 0 01-.75.75z"
        fill="url(#paint16_linear_46_1865)"
      />
      <path
        d="M59.25 30.25a.75.75 0 01-.75-.75V22H51a.75.75 0 110-1.5h8.25a.75.75 0 01.75.75v8.25a.75.75 0 01-.75.75z"
        fill="url(#paint17_linear_46_1865)"
      />
      <path
        d="M59.25 59.5H51a.75.75 0 110-1.5h7.5v-7.5a.75.75 0 111.5 0v8.25a.75.75 0 01-.75.75z"
        fill="url(#paint18_linear_46_1865)"
      />
      <path
        d="M30 59.5h-8.25a.75.75 0 01-.75-.75V50.5a.75.75 0 111.5 0V58H30a.75.75 0 110 1.5z"
        fill="url(#paint19_linear_46_1865)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_46_1865"
          x1={0.5}
          y1={24.2938}
          x2={80.5}
          y2={24.2938}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_46_1865"
          x1={42.637}
          y1={44.448}
          x2={47.25}
          y2={44.448}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_46_1865"
          x1={33.75}
          y1={34.7057}
          x2={47.2038}
          y2={34.7057}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_46_1865"
          x1={30.4456}
          y1={45.3003}
          x2={35.2519}
          y2={45.3003}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_46_1865"
          x1={47.1082}
          y1={51.0658}
          x2={48.8118}
          y2={51.0658}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_46_1865"
          x1={46.0394}
          y1={36.9368}
          x2={50.2509}
          y2={36.9368}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_46_1865"
          x1={28.8182}
          y1={34.5791}
          x2={45.1834}
          y2={34.5791}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_46_1865"
          x1={42.637}
          y1={44.448}
          x2={47.25}
          y2={44.448}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_46_1865"
          x1={33.75}
          y1={34.7057}
          x2={47.2038}
          y2={34.7057}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_46_1865"
          x1={30.4456}
          y1={45.3003}
          x2={35.2519}
          y2={45.3003}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_46_1865"
          x1={38.7}
          y1={48.7081}
          x2={43.8842}
          y2={48.7081}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_46_1865"
          x1={33.5274}
          y1={39.9132}
          x2={44.25}
          y2={39.9132}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_46_1865"
          x1={35.6207}
          y1={52.3187}
          x2={37.8076}
          y2={52.3187}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_46_1865"
          x1={37.611}
          y1={41.886}
          x2={41.25}
          y2={41.886}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_46_1865"
          x1={30.15}
          y1={30.9884}
          x2={53.25}
          y2={30.9884}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_46_1865"
          x1={27.75}
          y1={36.1736}
          x2={30.1451}
          y2={36.1736}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_46_1865"
          x1={21}
          y1={23.4608}
          x2={30.75}
          y2={23.4608}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_46_1865"
          x1={50.25}
          y1={23.4608}
          x2={60}
          y2={23.4608}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_46_1865"
          x1={50.25}
          y1={52.7108}
          x2={60}
          y2={52.7108}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_46_1865"
          x1={21}
          y1={52.7108}
          x2={30.75}
          y2={52.7108}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Fingerprint
