import * as React from 'react'

function ArrowRight(props) {
  return (
    <svg
      width={20}
      height={17}
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.351.451a1.2 1.2 0 011.697 0l7.2 7.2a1.2 1.2 0 010 1.698l-7.2 7.2a1.2 1.2 0 11-1.697-1.697L15.503 9.7H1.6a1.2 1.2 0 010-2.4h13.903L10.35 2.149a1.2 1.2 0 010-1.698z"
        fill="url(#paint0_linear_218_3)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_218_3"
          x1={0.4}
          y1={5.2017}
          x2={19.6}
          y2={5.2017}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FE7E" />
          <stop offset={1} stopColor="#00C4FE" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ArrowRight
